import React, {useState} from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import logo from "../images/logo_clear250x37.png";

const Header = ({ siteTitle }) => {
  const [menuActive, setMenuActive] = useState(false);
  
  return (
  <header
    style={{
      background: `rgba(255, 255, 255, 1)`,
      position: "fixed",
      width: "100%",
      zIndex: 100,
      boxShadow: "0 2px 10px 5px rgba(0, 0, 0, .1)"
    }}
  >
    <div style={{position: "relative"}}>
      <div className="header-left">
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          <img src={logo} alt={siteTitle}/>
        </Link>
      </div>
      <div className={menuActive ? `header-right` : `header-right nodisp`}>
        <ul>
          <li>
            <Link
              to="/usage"
              style={{
                color: `#333`,
                textDecoration: `none`,
              }}
            >
              <span>使い方</span>
            </Link>
          </li>
          <li>
            <Link
              to="/merit"
              style={{
                color: `#333`,
                textDecoration: `none`,
              }}
            >
              <span>特徴・メリット</span>
            </Link>
          </li>
          <li>
            <Link
              to="/qa"
              style={{
                color: `#333`,
                textDecoration: `none`,
              }}
            >
              <span>よくある質問</span>
            </Link>
          </li>
          <li>
            <a
              href="https://tanaoroshi.barpro.co.jp/"
              target="_blank"
              style={{
                color: `#333`,
                textDecoration: `none`,
              }}
            >
              <span>ログインページへ</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="header-menu">
        <a className={menuActive ? `menu-trigger active` : `menu-trigger`}
           onClick={() => setMenuActive(!menuActive)}>
          <span></span>
          <span></span>
          <span></span>
        </a>
      </div>
    </div>
  </header>
)}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
